
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp} = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: ['chartData'],
  data: () => ({
    chartDataRes: {
      labels: ["Desenrola", "Mercado do João"],
      datasets: [
        {
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
          ],
          borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
          data: [40, 20],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        fullSize: true,
        labels: {
          // This more specific font property overrides the global property
          fontSize: 15,
        },
        title: {
          font: 20,
        },
      },
    }
  }),
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    // this.chartDataRes.labels = this.chartData.map(item => item.name);
    // this.chartDataRes.datasets[0].data = this.chartData.map(item => item.count);
    this.renderChart(this.chartData, this.options);
  },
  // watch: {
  //   chartData () {
  //   this.chartDataRes.labels = this.chartData.map(item => item.name);
  //   this.chartDataRes.datasets[0].data = this.chartData.map(item => item.count);
  //   this.renderChart(this.chartDataRes, this.chartOptions);
  //     this.$data._chart.update()
  //   }
  // },
  // updated(){
  //   this.renderChart(this.chartData, this.options)
  //     this.$data._chart.update()

  // }
}
