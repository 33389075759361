import axios from 'axios'
import baseUrl from './baseUrl'
const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  if (err.response && err.response.status == 401) {
    //window.location.href = "/login"
  }
  return Promise.reject(err.response ? err.response.data.message : 'Houve uma falha na comunição, verifique sua internet e tente novamente')
}
const headers = { token: localStorage.getItem('token') }
let users = axios.create({ baseURL: baseUrl.tnAdmin, headers })


export const getNotices10 = (param) => users.post('/accesses/notices', param).then(({ data }) => data)
.catch(errors)

export const getAds10 = (data) => users.post('/accesses/ads', data).then(({ data }) => data)
.catch(errors)

export const getTotens10 = (data) => users.post('/accesses/totens', data).then(({ data }) => data)
.catch(errors)

export const getPartnersChart = (data) => users.post('/accesses/partners', data).then(({ data }) => data)
.catch(errors)


export const getChartHours = (data) => users.post('/accesses/charthours', data).then(({ data }) => data)
.catch(errors)
export const getChartDays = (data) => users.post('/accesses/chartdays', data).then(({ data }) => data)
.catch(errors)
export const getChartMonths = (data) => users.post('/accesses/chartmonths', data).then(({ data }) => data)
.catch(errors)

export const listNoticesPerPartners = (data) => users.post('/accesses/listNoticesPerPartners', data).then(({ data }) => data)
.catch(errors)

export const listAds = (data) => users.post('/accesses/listAds', data).then(({ data }) => data)
.catch(errors)





