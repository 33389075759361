<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Dashboards</v-toolbar-title>

      <v-divider class="mx-4" inset vertical></v-divider>

      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row class="pa-4">
      <v-col cols="12" sm="3">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datesFormatted"
              label="Inicío"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" no-title range></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="3">
        <v-combobox
          small-chips
          v-model="selectToten"
          :items="itemsToten"
          label="Totens:"
          multiple
        ></v-combobox>
      </v-col>
      <v-col cols="12" sm="3">
        <v-combobox
          small-chips
          v-model="selectRegion"
          :items="itemsRegion"
          label="Regiões:"
          multiple
        ></v-combobox>
      </v-col>
      <v-col cols="12" sm="3">
        <v-combobox
          small-chips
          v-model="selectPartner"
          :items="itemsPartner"
          label="Parceiros:"
          multiple
        ></v-combobox>
      </v-col>
      <!-- <v-col cols="4" sm="2">
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  style="width: 100%; margin-top: 10px"
                  color="primary"
                  dark
                  
                >
                  Exportar
                </v-btn>
              </template>
              <span>Exporte dados dos gráficos e tabelas em PDF ou CSV</span>
            </v-tooltip>
          </template>
          <v-list>
            <a
              :href="item.url"
              target="_blank"
              style="text-decoration: none"
              v-for="(item, index) in items"
              :key="index"
            >
              <v-list-item>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
            </a>
          </v-list>
        </v-menu>
      </v-col> -->
      <!-- <v-col cols="4" sm="2">
        <v-combobox
          small-chips
          v-model="selectCampaign"
          :items="itemsCampaign"
          label="Campanhas:"
          multiple
        ></v-combobox>
      </v-col> -->
    </v-row>
    <v-row class="pa-4">
      <v-col xs="12" sm="12" md="12">
        <v-card>
          <v-card-title
            style="
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span>Views e Clicks</span>
            <div style="display: flex; align-items: center">
              <v-checkbox
                v-model="itemsType"
                label="Notícias"
                color="primary"
                value="Notícia"
                hide-details
              ></v-checkbox>
              <v-checkbox
                class="ml-3"
                v-model="itemsType"
                label="Anúncios"
                color="primary"
                value="Anúncio"
                hide-details
              ></v-checkbox>
            </div>
          </v-card-title>
          <v-card-actions style="display: flex; justify-content: space-between">
            <div>
              <v-btn
                class="pl-0"
                :color="diario ? 'orange' : 'blue'"
                text
                :loading="loadingChartH"
                @click="
                  () => {
                    diario = !diario;
                    mensal = false;
                    anual = false;
                    semanal = false;
                  }
                "
              >
                <v-icon>{{
                  diario ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Dias
              </v-btn>
              <v-btn
                class="pl-0"
                :color="semanal ? 'orange' : 'blue'"
                text
                :loading="loadingChartD"
                @click="
                  () => {
                    semanal = !semanal;
                    mensal = false;
                    anual = false;
                    diario = false;
                  }
                "
              >
                <v-icon>{{
                  semanal ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Semanas
              </v-btn>
              <v-btn
                class="pl-0"
                :color="mensal ? 'orange' : 'blue'"
                text
                :loading="loadingChartM"
                @click="
                  () => {
                    mensal = !mensal;
                    semanal = false;
                    anual = false;
                    diario = false;
                  }
                "
              >
                <v-icon>{{
                  mensal ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Meses
              </v-btn>
            </div>
            <div>
              <v-btn
                class="ma-2"
                outlined
                color="primary"
                dark
                :loading="loadingChartD || loadingChartH || loadingChartM"
                :disabled="!diario && !mensal && !semanal"
                @click.prevent="downloadImage('viewsClicks')"
              >
                <v-icon dark>mdi-chart-bar</v-icon>
                Gráfico
              </v-btn>
              <!-- <v-btn class="ma-2" outlined color="primary">
                <v-icon dark>mdi-file-excel</v-icon>
                Gráfico
              </v-btn> -->
            </div>
          </v-card-actions>

          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%" v-if="diario">
              <v-divider></v-divider>
              <vertical-bar
                id="vcDaysChart"
                v-if="datacollectionH"
                :chart-data="datacollectionH"
              ></vertical-bar>
            </div>
          </v-expand-transition>
          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%" v-if="semanal">
              <v-divider></v-divider>
              <vertical-bar
                id="vcWeeksChart"
                v-if="datacollectionD"
                :chart-data="datacollectionD"
              ></vertical-bar>
            </div>
          </v-expand-transition>
          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%" v-if="mensal">
              <v-divider></v-divider>
              <vertical-bar
                id="vcMonthsChart"
                v-if="datacollectionM"
                :chart-data="datacollectionM"
              ></vertical-bar>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="$store.state.user.type == 'Administrador'" class="pa-4">
      <v-col
        style="box-shadow: unset !important; background: white"
        xs="12"
        sm="12"
        md="4"
      >
        <v-card :loading="loadingNoticePerPartners">
          <v-card-title>Notícias por parceiros</v-card-title>
          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%">
              <v-divider></v-divider>

              <v-list-item id="teste" two-line>
                <v-list-item-content>
                  <v-list-item-title class="mt-2"
                    ><strong
                      >{{ totalNotices }} Notícias cadastradas</strong
                    ></v-list-item-title
                  >
                  <v-list-item-title class="mt-2"
                    ><strong
                      >{{ totalPlaylistUtilize }} Utilizadas em playlist
                      ativas.</strong
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col
        style="box-shadow: unset !important; background: white"
        xs="12"
        sm="12"
        md="4"
      >
        <v-card :loading="loadingAds">
          <v-card-title>Anúncios</v-card-title>
          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%">
              <v-divider></v-divider>

              <v-list-item id="teste" two-line>
                <v-list-item-content>
                  <v-list-item-title class="mt-2"
                    ><strong
                      >{{ totalAds }} Anúncios cadastradas</strong
                    ></v-list-item-title
                  >
                  <v-list-item-title class="mt-2"
                    ><strong
                      >{{ totalPlaylistUtilizeAds }} Utilizadas em playlist
                      ativas.</strong
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pa-4">
      <v-col
        style="box-shadow: unset !important; background: white"
        xs="12"
        sm="12"
        md="4"
      >
        <v-card :loading="loadingNotice">
          <v-card-title>Notícias</v-card-title>

          <v-card-actions
            style="display: flex; justify-content: space-between"
            class="d-flex"
          >
            <!-- <v-btn color="blue" icon @click="show = !show">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                View
              </v-btn> -->
            <div>
              <v-btn
                class="pl-0"
                :color="noticeClicksShow ? 'orange' : 'blue'"
                text
                :loading="loadingNotice"
                @click="
                  () => {
                    noticeClicksShow = !noticeClicksShow;
                    noticeViewsShow = false;
                  }
                "
              >
                <v-icon>{{
                  noticeClicksShow ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Clicks
              </v-btn>
              <v-btn
                class=""
                :color="noticeViewsShow ? 'orange' : 'blue'"
                :loading="loadingNotice"
                text
                @click="
                  () => {
                    noticeViewsShow = !noticeViewsShow;
                    noticeClicksShow = false;
                  }
                "
              >
                <v-icon>{{
                  noticeViewsShow ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Views
              </v-btn>
            </div>

            <div>
              <v-btn
                
                class="ma-2"
                outlined
                small
                color="primary"
                :loading="loadingNotice"
                :disabled="!noticeClicksShow && !noticeViewsShow"
                @click.prevent="downloadXls('notices')"
              >
                <v-icon dark>mdi-file-excel</v-icon>
                Excel
              </v-btn>
            </div>
          </v-card-actions>

          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%" v-show="noticeClicksShow">
              <v-divider></v-divider>

              <v-list-item id="teste" two-line>
                <v-list-item-content v-if="itemsNoticeClicks.length > 0">
                  <span
                    v-for="(notice, index) in itemsNoticeClicks"
                    :key="notice.name"
                  >
                    <v-list-item-title class="mt-2"
                      ><strong>{{ index + 1 }}.</strong
                      >{{ notice.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ notice.count }} clicks</v-list-item-subtitle
                    >
                  </span>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="mt-2"
                    ><strong>Não possui clicks</strong></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%" v-show="noticeViewsShow">
              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content v-if="itemsNoticeViews.length > 0">
                  <span
                    v-for="(notice, index) in itemsNoticeViews"
                    :key="notice.name"
                  >
                    <v-list-item-title class="mt-2"
                      ><strong>{{ index + 1 }}.</strong
                      >{{ notice.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ notice.count }} view</v-list-item-subtitle
                    >
                  </span>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="mt-2"
                    ><strong>Não possui views</strong></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="4">
        <v-card :loading="loadingAds">
          <v-card-title>Anúncios</v-card-title>

          <v-card-actions
            style="display: flex; justify-content: space-between"
            class="d-flex"
          >
            <div>
              <v-btn
                class="pl-0"
                :color="adsClicksShow ? 'orange' : 'blue'"
                text
                :loading="loadingAds"
                @click="
                  () => {
                    adsClicksShow = !adsClicksShow;
                    adsViewsShow = false;
                  }
                "
              >
                <v-icon>{{
                  adsClicksShow ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Clicks
              </v-btn>
              <v-btn
                class=""
                :color="adsViewsShow ? 'orange' : 'blue'"
                :loading="loadingAds"
                text
                @click="
                  () => {
                    adsViewsShow = !adsViewsShow;
                    adsClicksShow = false;
                  }
                "
              >
                <v-icon>{{
                  adsViewsShow ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Views
              </v-btn>
            </div>
            <div>
              <v-btn
                
                class="ma-2"
                outlined
                small
                color="primary"
                :loading="loadingAds"
                :disabled="!adsClicksShow && !adsViewsShow"
                @click.prevent="downloadXls('ads')"
              >
                <v-icon dark>mdi-file-excel</v-icon>
                Excel
              </v-btn>
            </div>
          </v-card-actions>

          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%" v-show="adsClicksShow">
              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content v-if="itemsAdsClicks.length > 0">
                  <span v-for="(ads, index) in itemsAdsClicks" :key="index">
                    <v-list-item-title class="mt-2"
                      ><strong>{{ index + 1 }}.</strong
                      >{{ ads.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ ads.count }} clicks</v-list-item-subtitle
                    >
                  </span>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="mt-2"
                    ><strong>Não possui clicks</strong></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%" v-show="adsViewsShow">
              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content v-if="itemsAdsViews.length > 0">
                  <span v-for="(ads, index) in itemsAdsViews" :key="index">
                    <v-list-item-title class="mt-2"
                      ><strong>{{ index + 1 }}.</strong
                      >{{ ads.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ ads.count }} views</v-list-item-subtitle
                    >
                  </span>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="mt-2"
                    ><strong>Não possui views</strong></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="4">
        <v-card :loading="loadingTotem">
          <v-card-title>Totens</v-card-title>

          <v-card-actions style="display: flex; justify-content: space-between">
            <div>
              <v-btn
                class="pl-0"
                :color="totensClicksShow ? 'orange' : 'blue'"
                text
                :loading="loadingTotem"
                @click="
                  () => {
                    totensClicksShow = !totensClicksShow;
                    totensViewsShow = false;
                  }
                "
              >
                <v-icon>{{
                  totensClicksShow ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Clicks
              </v-btn>
              <v-btn
                class=""
                :color="totensViewsShow ? 'orange' : 'blue'"
                text
                :loading="loadingTotem"
                @click="
                  () => {
                    totensViewsShow = !totensViewsShow;
                    totensClicksShow = false;
                  }
                "
              >
                <v-icon>{{
                  totensViewsShow ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                Views
              </v-btn>
            </div>

            <div>
              <v-btn
                
                class="ma-2"
                outlined
                small
                color="primary"
                :loading="loadingTotem"
                :disabled="!totensClicksShow && !totensViewsShow"
                @click.prevent="downloadXls('totens')"
              >
                <v-icon dark>mdi-file-excel</v-icon>
                Excel
              </v-btn>
            </div>
          </v-card-actions>

          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%" v-show="totensClicksShow">
              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content v-if="itemsTotem10Clicks.length > 0">
                  <span
                    v-for="(totem, index) in itemsTotem10Clicks"
                    :key="index"
                  >
                    <v-list-item-title class="mt-2"
                      ><strong>{{ index + 1 }}.</strong
                      >{{ totem.name[0] }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ totem.count }} clicks</v-list-item-subtitle
                    >
                  </span>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="mt-2"
                    ><strong>Não possui clicks</strong></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
          <v-expand-transition style="width: 100%" class="w-100">
            <div style="width: 100%" v-show="totensViewsShow">
              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content v-if="itemsTotem10Views.length > 0">
                  <span
                    v-for="(totem, index) in itemsTotem10Views"
                    :key="index"
                  >
                    <v-list-item-title class="mt-2"
                      ><strong>{{ index + 1 }}.</strong
                      >{{ totem.name[0] }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ totem.count }} view</v-list-item-subtitle
                    >
                  </span>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="mt-2"
                    ><strong>Não possui views</strong></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pa-4">
      <v-col xs="12" sm="12" md="6">
        <v-card>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            "
          >
            <v-card-title>Parceiros de conteúdo - clicks</v-card-title>
            <div>
              <v-btn
                outlined
                small
                color="primary"
                :loading="loadingChart3"
                @click.prevent="downloadImage('partnersClicks')"
              >
                <v-icon dark>mdi-chart-bar</v-icon>
                Dashboard
              </v-btn>
              <v-btn
                
                class="ma-2"
                outlined
                small
                color="primary"
                :loading="loadingChart3"
                @click.prevent="downloadXls('partnersClicks')"
              >
                <v-icon dark>mdi-file-excel</v-icon>
                Excel
              </v-btn>
            </div>
          </div>
          <pie-chart
            id="partnersClicks"
            class="py-5"
            v-show="!loadingChart3"
            :chart-data="datacollection"
          ></pie-chart>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6">
        <v-card class="">
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            "
          >
            <v-card-title>Parceiros de conteúdo - views</v-card-title>

            <div>
              <v-btn
                outlined
                small
                color="primary"
                :loading="loadingChart3"
                @click.prevent="downloadImage('partnersViews')"
              >
                <v-icon dark>mdi-chart-bar</v-icon>
                Dashboard
              </v-btn>
              <v-btn
                
                class="ma-2"
                outlined
                small
                color="primary"
                :loading="loadingChart3"
                @click.prevent="downloadXls('partnersViews')"
              >
                <v-icon dark>mdi-file-excel</v-icon>
                Excel
              </v-btn>
            </div>
          </div>

          <pie-chart
            id="partnersViews"
            class="py-5"
            v-show="!loadingChart3"
            :chartData="datacollection2"
          ></pie-chart>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getTotens } from "../../services/totens";
import { getPartners } from "../../services/users";
import { getDay, isAfter } from "date-fns";
import { jsPDF } from "jspdf";
import baseUrl from "../../services/baseUrl";
import {
  getNotices10,
  getAds10,
  getTotens10,
  getPartnersChart,
  getChartHours,
  getChartDays,
  getChartMonths,
  listNoticesPerPartners,
  listAds,
} from "../../services/accesses";
// @ is an alias to /src
import _ from "lodash";
import PieChart from "./PieChart";
import VerticalBar from "./VerticalBar";
import { enUS, ptBR } from "date-fns/locale";
import { startOfMonth, endOfMonth, format } from "date-fns";
import jsFileDownload from "js-file-download";
import axios from "axios";
export default {
  name: "Dashboards",
  components: { PieChart, VerticalBar },
  data: function () {
    return {
      token: localStorage.getItem("token"),
      // Period Filter
      dates: [
        format(startOfMonth(new Date()), "yyyy-MM-dd", ptBR),
        format(endOfMonth(new Date()), "yyyy-MM-dd", ptBR),
      ],
      datesFormatted: [
        format(startOfMonth(new Date()), "dd/MM/yyyy", ptBR),
        format(endOfMonth(new Date()), "dd/MM/yyyy", ptBR),
      ],
      // Toten Filter
      selectToten: [],
      itemsToten: [],
      // Region Filter
      selectRegion: [],
      itemsRegion: [],
      // Campaign Filter
      selectCampaign: ["Campanha 1", "Campanha 2"],
      itemsCampaign: ["Campanha 1", "Campanha 2", "Campanha 3", "Campanha 4"],
      // Partner Filter
      selectPartner: [],
      itemsPartner: [],

      // TOP10
      itemsNoticeViews: [],
      itemsNoticeClicks: [],
      itemsAdsViews: [],
      itemsAdsClicks: [],
      itemsTotem10Views: [],
      itemsTotem10Clicks: [],
      partnersChartViews: [],
      partnersChartClick: [],

      countNoticesWithPlaylist: 0,
      totalNotices: 0,
      totalPlaylistUtilize: 0,
      totalAds: 0,
      totalPlaylistUtilizeAds: 0,

      loadingAds: false,
      loadingNotice: false,
      loadingTotem: false,
      loadingChart3: false,
      loadingCharts: false,
      loadingChartH: false,
      loadingChartD: false,
      loadingChartM: false,
      loadingNoticePerPartners: false,

      noticeViewsShow: false,
      noticeClicksShow: true,
      adsViewsShow: false,
      adsClicksShow: true,
      totensViewsShow: false,
      totensClicksShow: true,
      partnerClicksShow: false,
      partnerViewsShow: false,
      campaignClicksShow: false,
      campaignViewsShow: false,

      chartHoursView: [],
      chartHoursClick: [],
      data: {
        labels: ["Red", "Orange", "Yellow", "Green", "Blue"],
        datasets: [
          {
            label: "Dataset 1",
            data: 1,
          },
        ],
      },

      graphViewsClicks: [
        {
          title: "Dias",
          id: "vcDaysChart",
        },
        {
          title: "Semanas",
          id: "vcWeeksChart",
        },
        {
          title: "Meses",
          id: "vcMonthsChart",
        },
      ],
      itemsXls: [],

      datacollection: null,
      datacollection2: null,
      datacollectionH: null,
      datacollectionD: null,
      datacollectionM: null,
      show: false,
      menu1: false,
      menu2: false,

      anual: false,
      mensal: false,
      semanal: false,
      diario: true,

      lblH: [],
      valueHA: [],
      valueHV: [],

      lblD: [],
      valueDA: [],
      valueDV: [],

      lblM: [],
      valueMA: [],
      valueMV: [],

      itemsType: ["Anúncio", "Notícia"],
    };
  },

  watch: {
    dates() {
      this.datesFormatted = this.formatDate([...this.dates]);
      this.callApis();
    },
    endDate() {
      this.callApis();
    },
    selectToten() {
      this.callApis();
    },
    selectRegion() {
      this.callApis();
    },
    selectPartner() {
      this.callApis();
    },
    itemsType() {
      this.callsApisGraphs();
    },
  },
  mounted() {
    const baseUrl = "https://api.plataformatn.com.br";

    this.itemsXls = {
      noticeClicks: `${baseUrl.tnAdmin}/accesses/noticexls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&token=${this.token}`,
      noticeViews: `${baseUrl.tnAdmin}/accesses/noticexls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&views=true&token=${this.token}`,
      adsClicks: `${baseUrl.tnAdmin}/accesses/adsxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&token=${this.token}`,
      adsViews: `${baseUrl.tnAdmin}/accesses/adsxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&views=true&token=${this.token}`,
      totensClicks: `${baseUrl.tnAdmin}/accesses/totensxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}`,
      totensViews: `${baseUrl.tnAdmin}/accesses/totensxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&views=true&token=${this.token}`,
      partnersClicks: `${baseUrl.tnAdmin}/accesses/partnersxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&token=${this.token}`,
      partnersViews: `${baseUrl.tnAdmin}/accesses/partnersxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&views=true&token=${this.token}`,
    };

    getTotens().then((res) => {
      this.itemsToten = res.map((toten) => ({
        text: toten.name,
        value: toten.totemId,
      }));
      this.itemsRegion = _.uniq(res.map((item) => item.region));
    });
    getPartners().then((res) => {
      this.itemsPartner = res.map((partner) => ({
        text: partner.name,
        value: partner._id,
      }));
    });
    this.callApis();
  },
  methods: {
    mountXlsx(chartName) {
      this.itemsXls = {
        noticeClicks: `${baseUrl.tnAdmin}/accesses/noticexls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&partners=${JSON.stringify(this.selectPartner.map(partner=>partner.value))}&token=${this.token}`,
        noticeViews: `${baseUrl.tnAdmin}/accesses/noticexls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&views=true&partners=${JSON.stringify(this.selectPartner.map(partner=>partner.value))}&token=${this.token}`,
        adsClicks: `${baseUrl.tnAdmin}/accesses/adsxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&partners=${JSON.stringify(this.selectPartner.map(partner=>partner.value))}&token=${this.token}`,
        adsViews: `${baseUrl.tnAdmin}/accesses/adsxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&views=true&partners=${JSON.stringify(this.selectPartner.map(partner=>partner.value))}&token=${this.token}`,
        totensClicks: `${baseUrl.tnAdmin}/accesses/totensxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&partners=${JSON.stringify(this.selectPartner.map(partner=>partner.value))}&token=${this.token}`,
        totensViews: `${baseUrl.tnAdmin}/accesses/totensxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&partners=${JSON.stringify(this.selectPartner.map(partner=>partner.value))}&views=true&token=${this.token}`,
        partnersClicks: `${baseUrl.tnAdmin}/accesses/partnersxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&partners=${JSON.stringify(this.selectPartner.map(partner=>partner.value))}&token=${this.token}`,
        partnersViews: `${baseUrl.tnAdmin}/accesses/partnersxls?startDate=${this.dates[0]}&endDate=${this.dates[1]}&views=true&partners=${JSON.stringify(this.selectPartner.map(partner=>partner.value))}&token=${this.token}`,
      };
      return this.itemsXls[chartName];
    },
    formatDate(dates) {
      const date1 = format(new Date(dates[0] + "T00:00:00"), "dd/MM/yyyy", {
        locale: enUS,
      });
      if (!dates || dates.length == 1) return [date1, ""];
      const date2 = format(new Date(dates[1] + "T00:00:00"), "dd/MM/yyyy", {
        locale: enUS,
      });
      if (
        isAfter(
          new Date(dates[0] + "T00:00:00"),
          new Date(dates[1] + "T00:00:00")
        )
      ) {
        return [date2, date1];
      }

      return [date1, date2];
    },
    parseDate(date) {
      if (!date) return null;
      if (isAfter(new Date(date[0]), new Date(date[1])))
        return [date[1], date[0]];
      return [date[0], date[1]];
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    callsApisGraphs() {
      const type = this.itemsType;
      const startDate = this.dates[0];
      const endDate = this.dates[1];
      const totens = this.selectToten.map((totem) => totem.value);
      const partners = this.selectPartner.map((partner) => partner.value);
      const regions = this.selectRegion;
      if (this.itemsType.length == 0) {
        this.itemsType = ["Anúncio", "Notícia"];
      }
      this.loadingChartH = true;
      this.loadingChartD = true;
      this.loadingChartM = true;
      getChartHours({
        startDate,
        endDate,
        totens,
        partners,
        regions,
        type,
      }).then((res) => {
        this.loadingChartH = false;
        const view = res.hours.viewsHour.map((chart) => ({
          name: `${format(
            new Date(chart._id.date + "T01:00:00"),
            "dd/MM/yyyy"
          )} - ${chart._id.hour}H`,
          count: chart.count,
        }));
        const clicks = res.hours.accessesHour.map((chart) => ({
          name: `${format(
            new Date(chart._id.date + "T01:00:00"),
            "dd/MM/yyyy"
          )} - ${chart._id.hour}H`,
          count: chart.count,
        }));
        const lblAV = [...res.hours.accessesHour, ...res.hours.viewsHour];
        const orderLbl = lblAV
          .sort(
            (a, b) =>
              new Date(a._id.date) - new Date(b._id.date) ||
              a._id.hour - b._id.hour
          )
          .map((chart) => {
            return `${format(
              new Date(chart._id.date + "T01:00:00"),
              "dd/MM/yyyy"
            )} - ${chart._id.hour}H`;
          });

        this.lblH = [...new Set(orderLbl)];
        this.valueHA = this.lblH.map((item) => {
          const res = clicks.find((o) => o.name === item);
          if (res) return res.count;
          return 0;
        });
        this.valueHV = this.lblH.map((item) => {
          const res = view.find((o) => o.name === item);
          if (res) return res.count;
          return 0;
        });
        this.fillDataHorizontalChart(
          "H",
          this.lblH,
          this.valueHA,
          this.valueHV
        );
      });
      getChartDays({
        startDate,
        endDate,
        totens,
        partners,
        regions,
        type,
      }).then((res) => {
        const week = {
          0: "Domingo",
          1: "Segunda",
          2: "Terça",
          3: "Quarta",
          4: "Quinta",
          5: "Sexta",
          6: "Sábado",
        };
        this.loadingChartD = false;
        const view = res.days.viewsDays.map((chart) => ({
          name: `${format(new Date(chart._id + "T01:00:00"), "dd/MM/yyyy")} - ${
            week[getDay(new Date(chart._id + "T01:00:00"))]
          }`,
          count: chart.count,
        }));
        const clicks = res.days.accessesDays.map((chart) => ({
          name: `${format(new Date(chart._id + "T01:00:00"), "dd/MM/yyyy")} - ${
            week[getDay(new Date(chart._id + "T01:00:00"))]
          }`,
          count: chart.count,
        }));
        const lblAV = [...res.days.accessesDays, ...res.days.viewsDays];
        const orderLbl = lblAV
          .sort((a, b) => new Date(a._id) - new Date(b._id))
          .map((chart) => {
            return `${format(
              new Date(chart._id + "T01:00:00"),
              "dd/MM/yyyy"
            )} - ${week[getDay(new Date(chart._id + "T01:00:00"))]}`;
          });

        this.lblD = [...new Set(orderLbl)];
        this.valueDA = this.lblD.map((item) => {
          const res = clicks.find((o) => o.name === item);
          if (res) return res.count;
          return 0;
        });
        this.valueDV = this.lblD.map((item) => {
          const res = view.find((o) => o.name === item);
          if (res) return res.count;
          return 0;
        });
        this.fillDataHorizontalChart(
          "D",
          this.lblD,
          this.valueDA,
          this.valueDV
        );
      });
      getChartMonths({
        startDate,
        endDate,
        totens,
        partners,
        regions,
        type,
      }).then((res) => {
        this.loadingChartM = false;
        const view = res.months.viewsMonth.map((chart) => ({
          name: `${chart._id.month}/${chart._id.year}`,
          count: chart.count,
        }));
        const clicks = res.months.accessesMonth.map((chart) => ({
          name: `${chart._id.month}/${chart._id.year}`,
          count: chart.count,
        }));
        const lblAV = [...res.months.accessesMonth, ...res.months.viewsMonth];
        const orderLbl = lblAV
          .sort((a, b) => a._id.year - b._id.year || a._id.month - b._id.month)
          .map((chart) => {
            return `${chart._id.month}/${chart._id.year}`;
          });

        this.lblM = [...new Set(orderLbl)];
        this.valueMA = this.lblM.map((item) => {
          const res = clicks.find((o) => o.name === item);
          if (res) return res.count;
          return 0;
        });
        this.valueMV = this.lblM.map((item) => {
          const res = view.find((o) => o.name === item);
          if (res) return res.count;
          return 0;
        });
        this.fillDataHorizontalChart(
          "M",
          this.lblM,
          this.valueMA,
          this.valueMV
        );
      });
    },
    callApis() {
      const startDate = this.dates[0];
      const endDate = this.dates[1];
      if (startDate && endDate) {
        const totens = this.selectToten.map((totem) => totem.value);
        const partners = this.selectPartner.map((partner) => partner.value);
        const regions = this.selectRegion;
        this.loadingAds = true;
        this.loadingNotice = true;
        this.loadingTotem = true;
        this.loadingCharts = true;
        this.loadingChart3 = true;
        this.loadingNoticePerPartners = true;

        getNotices10({ startDate, endDate, totens, partners, regions }).then(
          (res) => {
            this.itemsNoticeViews = res.views.map((notice) => ({
              name: notice._id,
              count: notice.count,
            }));
            this.itemsNoticeClicks = res.accesses.map((notice) => ({
              name: notice._id,
              count: notice.count,
            }));
            this.loadingNotice = false;
          }
        );
        getAds10({ startDate, endDate, totens, partners, regions }).then(
          (res) => {
            this.itemsAdsViews = res.views.map((ad) => ({
              name: ad._id,
              count: ad.count,
            }));
            this.itemsAdsClicks = res.accesses.map((ad) => ({
              name: ad._id,
              count: ad.count,
            }));
            this.loadingAds = false;
          }
        );
        getTotens10({ startDate, endDate, totens, partners, regions }).then(
          (res) => {
            this.itemsTotem10Views = res.views.map((totem) => ({
              name: totem._id,
              count: totem.count,
            }));
            this.itemsTotem10Clicks = res.accesses.map((totem) => ({
              name: totem._id,
              count: totem.count,
            }));
            this.loadingTotem = false;
          }
        );
        getPartnersChart({
          startDate,
          endDate,
          totens,
          partners,
          regions,
        }).then((res) => {
          this.partnersChartViews = res.views.map((partner) => ({
            name: partner._id,
            count: partner.count,
          }));
          this.partnersChartClick = [
            ...res.accesses.map((partner) => ({
              name: partner._id,
              count: partner.count,
            })),
          ];
          this.fillDataPieAccess();
          this.loadingChart3 = false;
        });
        listNoticesPerPartners({
          startDate,
          endDate,
          totens,
          partners,
          regions,
        }).then((res) => {
          this.loadingNoticePerPartners = false;
          this.totalNotices = res.totalNotices;
          this.totalPlaylistUtilize = res.totalPlaylistUtilize;
          this.countNoticesWithPlaylist = res.countNoticesWithPlaylist;
        });
        listAds({ startDate, endDate, totens, partners, regions }).then(
          (res) => {
            this.totalAds = res.totalAds;
            this.totalPlaylistUtilizeAds = res.totalPlaylistUtilize;
          }
        );
        this.callsApisGraphs();
      }
    },

    downloadImage(chartName) {
      if (chartName == "viewsClicks")
        chartName = this.diario
          ? "vcDaysChart"
          : this.semanal
          ? "vcWeeksChart"
          : "vcMonthsChart";

      const chartsId = {
        partnersClicks: {
          selector: "#partnersClicks #pie-chart",
          name: "Parceiros Cliques - Imagem",
        },
        partnersViews: {
          selector: "#partnersViews #pie-chart",
          name: "Parceiros Visualizações - Imagem",
        },
        vcDaysChart: {
          selector: "#vcDaysChart #bar-chart",
          name: "Visualizações e Cliques no Dia",
        },
        vcWeeksChart: {
          selector: "#vcWeeksChart #bar-chart",
          name: "Visualizações e Cliques na Semana",
        },
        vcMonthsChart: {
          selector: "#vcMonthsChart #bar-chart",
          name: "Visualizações e Cliques no Mês",
        },
      };

      const { selector, name } = chartsId[chartName];
      let a = document.createElement("a");
      const canvas = document.querySelector(selector);
      const ctx = canvas.getContext("2d");

      ctx.globalCompositeOperation = "destination-over";

      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      a.setAttribute("download", name);
      const image = canvas.toDataURL("image/png");
      // a.setAttribute("href", image);
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [20, 5],
      });
      pdf.addImage(image, "png", 1, 1);
      pdf.setFillColor(204, 204, 204, 0);
      pdf.rect(10, 10, 150, 160, "F");
      pdf.save("Gráfico.pdf");
      // a.click();
      // a.remove();
    },
    async downloadXls(chartName) {
      if (chartName == "notices")
        chartName = this.noticeClicksShow ? "noticeClicks" : "noticeViews";
      if (chartName == "ads")
        chartName = this.adsClicksShow ? "adsClicks" : "adsViews";
      if (chartName == "totens")
        chartName = this.totensClicksShow ? "totensClicks" : "totensViews";
      if (chartName == "partnersClicks") chartName = "partnersClicks";
      if (chartName == "partnersViews") chartName = "partnersViews";
      this.loadingNotice = true
      this.loadingAds = true
      this.loadingTotem = true

      const xlsLinks = this.mountXlsx(chartName);

      await axios
        .get(xlsLinks, { responseType: "blob", withCredentials: true })
        .then((response) => {
          jsFileDownload(
            response.data,
            response.headers["content-disposition"].split("=")[1]
          );
        });
      this.loadingNotice = false;
      this.loadingAds = false;
      this.loadingTotem = false;
    },
    fillDataPieAccess() {
      this.datacollection = {
        labels: this.partnersChartClick.map((item) => item.name),
        datasets: [
          {
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(147, 250, 165, 0.2)",
              "rgba(241, 90, 34, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(147, 250, 165, 1)",
              "rgba(241, 90, 34, 1)",
            ],
            data: this.partnersChartClick.map((item) => item.count),
          },
        ],
      };
      this.datacollection2 = {
        labels: this.partnersChartViews.map((item) => item.name),
        datasets: [
          {
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(147, 250, 165, 0.2)",
              "rgba(241, 90, 34, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(147, 250, 165, 1)",
              "rgba(241, 90, 34, 1)",
            ],
            data: this.partnersChartViews.map((item) => item.count),
          },
        ],
      };
    },
    fillDataHorizontalChart(chart, labels, dataOne, dataTwo) {
      const variable = {
        labels,
        datasets: [
          {
            label: "Click",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: ["rgba(54, 162, 235, 1)"],
            data: dataOne,
          },
          {
            label: "View",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: ["rgba(255, 99, 132, 1)"],
            data: dataTwo,
          },
        ],
      };
      if (chart == "H") this.datacollectionH = variable;
      if (chart == "D") this.datacollectionD = variable;
      if (chart == "M") this.datacollectionM = variable;
    },
  },
};
</script>
<style>
* {
  box-shadow: unset;
}
</style>