
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['chartData'],
  data: () => ({
    chartDataRes: {
      labels: ["10H", "12H"],
      datasets: [
        {
          label: 'Click',
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: ["rgba(54, 162, 235, 1)"],
          data: [40, 20],
        },
         {
          label: 'View',
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: ["rgba(255, 99, 132, 1)"],
          data: [40, 20],
        },
        
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
          xAxes: [
            {
              stacked: false,
              fontSize: 20,
            },
          ],
          yAxes: [
            {
              ticks: {
                fontSize: 12,
                beginAtZero: true,
                steps: 10,
                stepValue: 6,
              },
            },
          ],
        },
      legend: {
        fullSize: true,
        labels: {
          fontSize: 15,
        },

      },
    }
  }),
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
}